<template>
	<div>
		<h1>test</h1>
	</div>
</template>

<script>
	export default {
		setup() {
			// eslint-disable-next-line
			myUndefinedFunction()
				// eslint-disable-next-line
				.then(result => {})
				// eslint-disable-next-line
				.catch(err => {
					console.log(err)
				})
		},
	}
</script>
